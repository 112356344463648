import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    config: {
        initialColorMode: "light", // Start with dark mode
        useSystemColorMode: false, // Disable system preference
    },
    styles: {
        global: {
            'html, body': {
                margin: 0,
                padding: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
            },
            body: {
                color: "gray.900",
                bg: "white",
                fontFamily: 'Open Sans, sans-serif',
            },
            '#root': {
                height: '100%',
                minH: '100vh',
            },
        },
    },
    fonts: {
        heading: 'Open Sans, sans-serif',
        body: 'Open Sans, sans-serif',
    },
    textStyles: {
        subtitle: {
            fontSize: ['2md'],
            fontWeight: 'bold',
            lineHeight: 'tight',
        },
        heroMedium: {
            fontSize: ['2xl', '3xl', '5xl'],
            fontWeight: 'bold',
            lineHeight: 'tight',
        },
        heroLarge: {
            fontSize: ['4xl', '5xl', '8xl'],
            fontWeight: 'bold',
            lineHeight: 'tight',
        },
    },
    fontSizes: {
        '1md': '16px',
        '2md': '28px',
        '5xl': '48px',
        '6xl': '64px',
        '8xl': '104px',
    },
    components: {
        Text: {
            variants: {
                body2: {
                    fontFamily: 'Open Sans, sans-serif', // Use the defined font
                },
                body3: {
                    fontFamily: 'Open Sans, sans-serif', // Use the defined font
                }
            }
        },
        Button: {
            sizes: {
                xxl: { // Custom size name
                    h: "60px", // height of the button
                    fontSize: "lg", // font size of the button text
                    px: "32px", // padding left and right
                },
            },
        },
    },
    colors: {
        body: {
            0: '#637381',
            2: '#1E90FF'
        },
        primary: {
            0: '#FFFFFF',
            50: '#E3F2FD',
            100: '#BBDEFB',
            150: '#FCFCFC',
            170: '#f3f3f3',
            180: '#eaeaea',
            200: '#f3f3f3',
        },
        navigation: {
            0: '#000000',
            50: '#292929',
            100: '#222222',
            125: '#494949',
            150: '#595959',
            200: '#777777',
            225: '#999999',
            250: '#444444'
        },
        alert: {
            0: '#de7878',
            50: '#4cb2de',
            100: '#26ff7e',
        },
        content: {
            0: '#292929',
            50: '#595959',
            100: '#a8a8a8',
        },
        button: {
            0: '#0071E3',
            50: '#595959',
            100: '#a8a8a8',
        },
        brand: {
            900: "#1A202C", // Darker shades of blue-grey for the sidebar background
            800: "#2D3748",
            700: "#4A5568",
            // Add more colors as needed
        },
    }
});

export default theme;
