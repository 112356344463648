// src/routes/protected.tsx
import { Navigate, Route, Routes } from 'react-router-dom';
import {BackendRoutes} from "~/apps/backend";
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const token = useSelector((state: RootState) => state.auth.token);

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

const ProtectedRoutes = () => {
    return (
        <Routes>
            <Route path="/*" element={<ProtectedRoute><BackendRoutes /></ProtectedRoute>} />
        </Routes>
    );
};

export default ProtectedRoutes;
