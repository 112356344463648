import { Box, FormControl, Menu, MenuButton, MenuItem, MenuList, Button as ChakraButton } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {ModelSettingsProps} from "~/utils/types.ts";



const ModelSettings: React.FC<ModelSettingsProps> = ({ modelSettings, handleSettingChange, modelType }) => {


    return (
        <Box>
            <Box>
                <FormControl id="quality">
                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            rightIcon={<ChevronDownIcon />}
                            bg="gray.700"
                            color="primary.0"
                            _hover={{ bg: 'gray.600' }}
                            w="100%"
                            mt={2}
                        >
                            Quality: {modelSettings.quality || "Select Quality"}
                        </MenuButton>
                        <MenuList bg="gray.700" borderColor="gray.600">
                            <MenuItem onClick={() => handleSettingChange("quality", "High")} bg="gray.700" color="primary.0" _hover={{ bg: 'gray.600' }}>
                                High
                            </MenuItem>
                            <MenuItem onClick={() => handleSettingChange("quality", "Medium")} bg="gray.700" color="primary.0" _hover={{ bg: 'gray.600' }}>
                                Medium
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </FormControl>
            </Box>
            <Box>
                <FormControl id="aspect_ratio">
                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            rightIcon={<ChevronDownIcon />}
                            bg="gray.700"
                            color="primary.0"
                            _hover={{ bg: 'gray.600' }}
                            w="100%"
                            mt={2}
                        >
                            Aspect Ratio: {modelSettings.aspectRatio === "1:1" ? "Portrait" : "Landscape"}
                        </MenuButton>
                        <MenuList bg="gray.700" borderColor="gray.600">
                            <MenuItem onClick={() => handleSettingChange("aspectRatio", "1:1")} bg="gray.700" color="primary.0" _hover={{ bg: 'gray.600' }}>
                                Portrait (1:1)
                            </MenuItem>
                            <MenuItem onClick={() => handleSettingChange("aspectRatio", "16:9")} bg="gray.700" color="primary.0" _hover={{ bg: 'gray.600' }}>
                                Landscape (16:9)
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </FormControl>
            </Box>
            <Box>
                <FormControl id="no_of_images">
                    {modelType !== 'flux' && (
                        <Menu>
                            <MenuButton
                                as={ChakraButton}
                                rightIcon={<ChevronDownIcon />}
                                bg="gray.700"
                                color="primary.0"
                                _hover={{ bg: 'gray.600' }}
                                w="100%"
                                mt={2}
                            >
                                Images Per Prompt: {modelSettings.no_of_images}
                            </MenuButton>
                            <MenuList bg="gray.700" borderColor="gray.600">
                                {[...Array(4)].map((_, index) => {
                                    const imageCount = index + 1;
                                    return (
                                        <MenuItem
                                            key={imageCount}
                                            onClick={() => handleSettingChange("no_of_images", imageCount.toString())}
                                            bg="gray.700"
                                            color="primary.0"
                                            _hover={{ bg: 'gray.600' }}
                                        >
                                            Generate {imageCount} Image{imageCount > 1 ? 's' : ''} per prompt
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Menu>
                    )}
                </FormControl>
            </Box>
        </Box>
    );
}

export default ModelSettings;
