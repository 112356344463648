import { useState } from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Box, Text, Link, CloseButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { BillingStatus as BillingStatusType } from '~/utils/types';

interface BillingAlertsProps {
    billingStatus: BillingStatusType;
    top: number;
}

const BillingAlerts: React.FC<BillingAlertsProps>=({ billingStatus, top }) => {
    const [isFreeTrialAlertVisible, setFreeTrialAlertVisible] = useState(true);

    if (!billingStatus || !isFreeTrialAlertVisible) {

        if ((billingStatus?.remaining_images || 0) > 0) {
            return null;
        }
    }

    if (billingStatus?.billing_price_id) {
        if((billingStatus?.remaining_images || 0) > 15) {
            return null;
        }
    }

    const position = "relative";
    return (
        <>
            <Alert
                status="info"
                borderRadius="md"
                mb={6}
                position={position}
                top={`${top}px`}
                left="0"
                right="0"
                zIndex={0}
            >
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle color="gray.800">
                        You can generate
                        <Text as="span" color="body.2" ml={1} mr={1}>
                            <strong>{billingStatus?.remaining_images?.toString() || 0}</strong>
                        </Text>
                        {!billingStatus?.billing_price_id ? (
                            <Text as={'span'}>
                                more free images in your trial using our test models.
                            </Text>
                        ) : (
                            <Text as={'span'}>
                                more images this month.
                            </Text>
                        )}
                    </AlertTitle>
                    <AlertDescription color="gray.800">
                        {((billingStatus?.remaining_images ?? 0 ) <= 0 && !billingStatus?.billing_price_id) && (
                            <Text>
                                You ran out of free credits. Please
                                <Link ml={1} mr={1}
                                      fontWeight="bold"
                                      as={RouterLink} color="body.2" to="/c-panel/billing">upgrade now!
                                </Link>
                            </Text>
                        )}
                        {((billingStatus?.remaining_images ?? 0 > 0) && !billingStatus?.billing_price_id) && (
                            <Text>
                                If you want to train with your own photos
                                <Link ml={1} mr={1}
                                      fontWeight="bold"
                                      color="body.2"
                                      as={RouterLink} to="/c-panel/billing">upgrade now</Link>
                                to unlock more features.
                            </Text>
                        )}
                    </AlertDescription>
                </Box>
                <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => setFreeTrialAlertVisible(false)}
                />
            </Alert>
        </>
    );
};

export default BillingAlerts;
