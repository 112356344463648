import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {ChakraProvider, Container} from '@chakra-ui/react';
import App from './App';
import { store } from './store';
import './index.css';
import theme from "./theme.ts";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || "0.1"),
    tracePropagationTargets: [
        "localhost",
        "apps.leadslide.com",
        "leadslide.com",
        new RegExp(import.meta.env.VITE_APP_BASE_API_URL.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    ],
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE || "0.01"),
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || "1.0"),
    beforeSend(event) {
        if (event.level !== 'error') {
            return null;
        }
        return event;
    },
});
ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store} >
            <ChakraProvider theme={theme}>
                <Container maxW="full" p="0">
                    <App />
                </Container>
            </ChakraProvider>
        </Provider>
    </React.StrictMode>
);
