import {
    Box,
    Button,
    Image,
    Text,
    useDisclosure,
    useBoolean,
    VStack,
    Heading,
    Spinner,
    useToast
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "~/store";
import { useEffect, useState } from "react";
import {downloadImage, fetchImage} from "~/store/gallerySlice.ts";
import {useParams} from "react-router-dom";
import BackgroundModal from "~/apps/backend/components/modals/BackgroundModal.tsx";

const RemoveBackgroundDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const image = useSelector((state: RootState) => state.gallery.image);
    const { public_id } = useParams<{ public_id: string }>();
    const [showOriginal, setShowOriginal] = useBoolean(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(true);
    const toast = useToast();
    const allowGenerate = false;

    useEffect(() => {
        if (public_id) {
            setLoading(true);
            dispatch(fetchImage(public_id)).finally(() => setLoading(false));
        }
    }, [dispatch, public_id]);

    const afterImage = image?.new_background_url || image?.no_background_image_url;

    const handleDownloadImage = async (public_id: string) => {
        try {
            await dispatch(downloadImage(public_id)).unwrap();
            toast({
                title: 'Download started',
                description: `Image ${public_id} is downloading.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Download failed',
                description: error as string,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    if(!image) {
        return (
            <Box bgColor="gray.800" minH="100vh" color="white" py={10} px={4}>
                <VStack spacing={6} maxW="800px" mx="auto" textAlign="center">
                    <Heading as="h1" size="lg">Image Details</Heading>
                    <Text fontSize="md" color="gray.400">
                        No image available
                    </Text>
                </VStack>
            </Box>
        );
    }


    return (
        <Box bgColor="gray.800" minH="100vh" color="white" py={10} px={4}>
            <VStack spacing={6} maxW="800px" mx="auto" textAlign="center">
                <Heading as="h1" size="lg">Image Details</Heading>
                <Text fontSize="md" color="gray.400">
                    Here you can view the before and after versions of your image. Toggle between the original and modified image, generate new background options, or download the image as needed.
                </Text>

                {!allowGenerate && (
                    <Text fontSize="md" color="gray.100">
                        The ability to change backgrounds is currently in development and will be available soon. Please
                        check back later for updates, or use the support button.
                    </Text>
                )}

                {loading ? (
                    <Spinner size="xl" color="teal.300" />
                ) : image && (image.image_url || afterImage) ? (
                    <Box
                        position="relative"
                        maxW="100%"
                        maxH="500px"
                        minW="300px"    // Minimum width for consistency
                        minH="300px"    // Minimum height for consistency
                        overflow="hidden"
                        borderRadius="md"
                        boxShadow="lg"
                    >
                        {/* After Image */}
                        {afterImage && (
                            <Image
                                src={afterImage}
                                alt="After Image"
                                position="absolute"
                                top="0"
                                left="0"
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                opacity={showOriginal ? 0 : 1}
                                transition="opacity 0.5s ease"
                                zIndex={showOriginal ? 1 : 2}
                            />
                        )}

                        {/* Original Image */}
                        {image.image_url && (
                            <Image
                                src={image.image_url}
                                alt="Original Image"
                                position="absolute"
                                top="0"
                                left="0"
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                opacity={showOriginal ? 1 : 0}
                                transition="opacity 0.5s ease"
                                zIndex={showOriginal ? 2 : 1}
                            />
                        )}
                    </Box>
                ) : (
                    <Text color="gray.400">No image available</Text>
                )}

                <VStack spacing={4}>
                    <Button
                        colorScheme="blue"
                        onClick={setShowOriginal.toggle}
                        size="md"
                        width="200px"
                    >
                        {showOriginal ? "Show Modified" : "Show Original"}
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={() => handleDownloadImage(image.public_id)}
                        size="md"
                        width="200px"
                    >
                        Download Image
                    </Button>
                    {allowGenerate && (
                        <Button
                            colorScheme="blue"
                            onClick={onOpen}
                            size="md"
                        >
                            Add New Background
                        </Button>
                    )}
                </VStack>
            </VStack>
            <BackgroundModal isVisible={isOpen} onClose={onClose}>

            </BackgroundModal>
        </Box>
    );
};

export default RemoveBackgroundDetail;
