import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollections, createCollection } from '~/store/collectionSlice';
import { RootState, AppDispatch } from '~/store';
import {
    Box, Heading, Text, VStack, HStack, IconButton, Button, Input, Textarea,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, useDisclosure, useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Collections = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { collections, next, previous, loading } = useSelector((state: RootState) => state.collections);
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const toast = useToast();

    useEffect(() => {
        dispatch(fetchCollections({model_type: 'custom'}));
    }, [dispatch]);

    const handlePageChange = (url: string | null, direction: string) => {
        if (url) {
            dispatch(fetchCollections({ url, model_type: 'custom' }));
            setCurrentPage(direction === 'next' ? currentPage + 1 : currentPage - 1);
        }
    };

    const handleModelClick = (public_id: string) => {
        navigate(`/c-panel/model/${public_id}`);
    };

    const handleAddModel = async () => {
        if (newName) {
            try {
                // Dispatch and unwrap to access the response data, including public_id
                const result = await dispatch(createCollection({ name: newName, description: newDescription })).unwrap();

                // Navigate to the new model's detail page
                navigate(`/c-panel/model/${result.public_id}`);

                // Clear input fields and close modal
                setNewName('');
                setNewDescription('');
                onClose();
            } catch (error) {
                if ((error as Error).message.includes("code 403")) {
                    // Show specific error message for 403 error
                    navigate('/c-panel/billing?alt=lmtc');
                } else {
                    // Show a generic error message
                    toast({
                        title: "An error occurred",
                        description: "Failed to create model. Please try again later.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        }
    };

    return (
        <Box p={6} bg="gray.800" minH="100vh">
            <Heading as="h1" size="xl" mb={6}>Models</Heading>

            {loading ? <Text>Loading...</Text> : null}

            <Button colorScheme="blue" mb={6} onClick={onOpen}>Add Model</Button>

            <VStack spacing={4} align="stretch">
                {collections.map((collection) => (
                    <Box
                        key={collection.id}
                        p={5}
                        shadow="md"
                        borderWidth="1px"
                        onClick={() => handleModelClick(collection.public_id)}
                        bg="gray.800" // Set initial background color
                        _hover={{
                            cursor: 'pointer',
                            bg: 'gray.700', // Light grey on hover
                        }}
                        borderRadius="md" // Optional for rounded corners
                    >
                        <Heading fontSize="lg">{collection.name}</Heading>
                        <Text mt={2}>{collection.description || "No description available"}</Text>
                        <Text mt={1} fontSize="sm" color="gray.500">
                            Created at: {new Date(collection.created_at).toLocaleDateString()}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            Training State: {collection.training_status}
                        </Text>
                    </Box>
                ))}
            </VStack>

            {next || previous && (
                <HStack mt={6} spacing={4} justify="center">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        isDisabled={!previous}
                        onClick={() => handlePageChange(previous, 'prev')}
                        aria-label="Previous Page"
                    />
                    <Text>Page {currentPage}</Text>
                    <IconButton
                        icon={<ChevronRightIcon />}
                        isDisabled={!next}
                        onClick={() => handlePageChange(next, 'next')}
                        aria-label="Next Page"
                    />
                </HStack>
            )}


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor="gray.800">
                    <ModalHeader color='primary.0'>Add New Model</ModalHeader>
                    <ModalCloseButton  color='primary.0' />
                    <ModalBody>
                        <Input
                            color='primary.0'
                            placeholder="Model Name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            mb={3}
                        />
                        <Textarea
                            color='primary.0'
                            placeholder="Description"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleAddModel} isDisabled={!newName}>
                            Create Model
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Collections;
