import {
    Box,
    Button,
    Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader, Text,
    ModalOverlay, Spinner, useToast, Grid, AspectRatio
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "~/store";
import {useEffect, useState} from "react";
import {fetchImages, uploadImage} from "~/store/gallerySlice.ts";
import {useNavigate} from "react-router-dom";

const RemoveBackground = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const images = useSelector((state: RootState) => state.gallery.images);
    const loading = useSelector((state: RootState) => state.gallery.loading);
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchImages());
    }, []);

    const handleImageUpload = async () => {
        if(!imageFile) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append('image', imageFile);
            await dispatch(uploadImage(formData)).unwrap();
            setIsOpen(false);
            toast({
                title: 'Image uploaded',
                description: 'Image uploaded successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            if ((error as Error).message.includes("code 403")) {
                // Show specific error message for 403 error
                navigate('/c-panel/billing?alt=rmbck');
                return;
            }
            toast({
                title: 'Error uploading image',
                description: 'An error occurred while uploading the image',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }

    const loadDetail = (public_id: string) => {
        navigate(`/c-panel/remove-background/${public_id}`);
    }
    return (
        <Box p={6} bg="gray.800" minH="100vh">
            <h1>Remove Background</h1>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="primary.0">
                    <ModalHeader>Add Image to Collection</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
                            bg="gray.700"
                            color="primary.0"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleImageUpload} isDisabled={!imageFile || loading}>
                            {loading ? <Spinner /> : 'Upload Image'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Text mt={1}>
                Upload an image to remove the background
            </Text>
            <Button
                colorScheme="blue"
                my={4}
                onClick={() => setIsOpen(true)} disabled={loading}>
                Upload Image
            </Button>

            <Box>
                <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={4}>
                    {images.map((image) => (
                        <AspectRatio ratio={1} key={image.public_id} overflow="hidden">
                            <Box
                                position="relative"
                                width="100%"
                                height="100%"
                                borderWidth={1}
                                _before={{
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${image.image_url})`,
                                    backgroundColor: '#1A202C',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    transition: 'transform 0.5s ease',
                                    transform: 'translateX(0)',
                                    zIndex: 2,
                                }}
                                _hover={{
                                    _before: {
                                        transform: 'translateX(100%)',
                                    },
                                }}
                            >
                                <Box
                                    as="img"
                                    src={image.no_background_image_url}
                                    alt={image.public_id}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                />
                                <Button
                                    onClick={() => loadDetail(image.public_id)}
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    zIndex="3"
                                    backgroundColor="rgba(0, 0, 0, 0.6)"
                                    color="white"
                                    px={4}
                                    py={2}
                                    _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                                    borderRadius="md"
                                >
                                    Details
                                </Button>
                            </Box>
                        </AspectRatio>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default RemoveBackground;