import {Box, Flex, Text, Link, VStack, HStack} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";

const Footer = () => {
    return (
        <Box bg="navigation.100" color="white" p={10} w={{ base: "100%" }} minH={200}>
            <Flex direction={{ base: "column", md: "row" }} justify="space-between">

                <Box w={{ base: "100%", md: "35%" }} pr={{ base: 5, md: 50 }}>
                    <Text fontSize="lg" mb={2}></Text>
                    <HStack spacing={4}>
                        <Text color="navigation.200">
                            &copy;Copyright Cartario 2024
                        </Text>
                    </HStack>
                </Box>



                <Box w={{ base: "100%", md: "15%" }}>

                    <Text fontSize="lg" mb={2}>Contact</Text>
                    <Text fontSize="sm" mb={8} color="navigation.200">
                        support@cartario.com
                    </Text>

                </Box>

                <VStack w={{ base: "100%", md: "20%" }} align="start" spacing={1} mt={{base: 4, md: 0}}>
                    <Text fontSize="lg" mb={2}>Menu</Text>
                    <Link fontSize={'sm'} as={RouterLink} _hover={{ textDecoration: 'none', color: 'navigation.225' }} to="/auth/login" color="navigation.200">Login</Link>
                    <Link fontSize={'sm'} as={RouterLink} _hover={{ textDecoration: 'none', color: 'navigation.225' }} to="/auth/register" color="navigation.200">Signup</Link>
                </VStack>
            </Flex>
        </Box>
    );
};

export default Footer;
