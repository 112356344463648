import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../utils/api';
import API_URLS from '../utils/urls';
import {RootState} from "~/store/index.ts";

// Define the structure of an individual collection item
interface Collection {
    id: number;
    name: string;
    public_id: string;
    description: string | null;
    created_at: string;
    updated_at: string;
    state: string;
    images: Array<{
        id: number;
        collection: number;
        image_file: string;
        caption: string;
        uploaded_at: string;
    }>;
    training_status: string;
}

interface TrainingStatus {
    public_id: string;
    status: string;
    error_message?: string;
}

// Define the structure of the collection state
interface CollectionState {
    collections: Collection[];
    count: number;
    next: string | null;
    previous: string | null;
    loading: boolean;
    error: string | null;
    currentCollection: Collection | null; // Added for current collection details
    trainingStatus: TrainingStatus | null; // Added for current training status
}

// Initial state with pagination fields
const initialState: CollectionState = {
    collections: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    error: null,
    currentCollection: null,
    trainingStatus: null,
};

// Fetch collections with pagination
export const fetchCollections = createAsyncThunk(
    'collections/fetchCollections',
    async ({ url, model_type }: { url?: string, model_type?:string } = {}) => {
        let endpoint = url || API_URLS.COLLECTIONS.GET;
        if(model_type) {
            endpoint = `${endpoint}?model_type=${model_type}&state=active`;
        }
        const response = await API.get(endpoint);
        return response.data;
    }
);

// Fetch a single collection (model) detail
export const fetchCollectionDetail = createAsyncThunk(
    'collections/fetchCollectionDetail',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/`);
        return response.data;
    }
);

// Fetch training status
export const fetchTrainingStatus = createAsyncThunk(
    'collections/fetchTrainingStatus',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/training/status`);
        return response.data;
    }
);

export const trainModel = createAsyncThunk(
    'collections/trainModel',
    async ({ public_id, is_product }: { public_id: string; is_product: boolean }, { dispatch, getState, rejectWithValue }) => {
        try {
            const response = await API.post(`/cartario/collection/${public_id}/train/`, {
                is_product,
            });

            // Update collection status to 'active' after a successful request
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection && currentCollection.public_id === public_id) {
                dispatch(updateCollectionStatus('active'));
            }

            return response.data;
        } catch (error: any) {
            // Handle the error within the thunk and pass it back
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);


export const createCollection = createAsyncThunk(
    'collections/createCollection',
    async (data: { name: string; description?: string }) => {
        const response = await API.post('/cartario/collections/', data);
        return response.data;
    }
);

export const uploadImage = createAsyncThunk(
    'collections/uploadImage',
    async (formData: FormData, { dispatch, getState }) => {
        try {
            // Axios will automatically set the Content-Type to multipart/form-data for FormData requests
            const response = await API.post('/cartario/images/', formData);

            const image = response.data;

            // Push the newly uploaded image to the current collection
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection) {
                dispatch(addImageToCollection(image)); // Dispatch the reducer to add the image
            }

            return image;
        } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
        }
    }
);

export const deleteImage = createAsyncThunk(
    'collections/deleteImage',
    async (imageId: number, { dispatch, getState, rejectWithValue }) => {
        try {
            await API.delete(`/cartario/image/${imageId}/`);

            // After deleting, update the state by removing the image
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection) {
                dispatch(removeImageFromCollection(imageId)); // Use reducer to remove the image from state
            }
        } catch (error: any) {
            console.error("Error deleting image:", error);
            return rejectWithValue(error.response?.data || "Failed to delete image");
        }
    }
);

const collectionSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        addImageToCollection: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.images.push(action.payload);
            }
        },
        updateCollectionStatus: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.training_status = action.payload;
            }
        },
        removeImageFromCollection: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.images = state.currentCollection.images.filter(
                    (image) => image.id !== action.payload
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetching collections
            .addCase(fetchCollections.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollections.fulfilled, (state, action) => {
                state.loading = false;
                state.collections = action.payload.results;
                state.count = action.payload.count;
                state.next = action.payload.next;
                state.previous = action.payload.previous;
            })
            .addCase(fetchCollections.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch collections';
            })

            // Fetching collection detail
            .addCase(fetchCollectionDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollectionDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.currentCollection = action.payload;
            })
            .addCase(fetchCollectionDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch collection details';
            })

            // Fetching training status
            .addCase(fetchTrainingStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTrainingStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.trainingStatus = action.payload;
                if (state.currentCollection) {
                    state.currentCollection.training_status = action.payload.status; // Update the training status in the collection
                }
            })
            .addCase(fetchTrainingStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch training status';
            })
            .addCase(uploadImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(uploadImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to upload image';
            })
            // Train Model Case
            .addCase(trainModel.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(trainModel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to train model';
            })
            .addCase(createCollection.fulfilled, (state, action) => {
                state.collections.push(action.payload); // Add the new collection to the state
            })
            .addCase(deleteImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteImage.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to delete image';
            });
    },
});

export const { addImageToCollection, updateCollectionStatus, removeImageFromCollection } = collectionSlice.actions;
export default collectionSlice.reducer;
