import React from 'react';
import {Box} from "@chakra-ui/react";
import Footer from "../../frontend/components/Footer.tsx";

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <Box>
            <Box as="main" p={0} bg="white">
                {children}
            </Box>
            <Footer />
        </Box>
    );
}

export default MainLayout;
