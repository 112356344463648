import { Box, Heading, Text, Button, VStack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Ensure you're using React Router for navigation

const PermissionNotGranted = () => {
    const bgColor = useColorModeValue('gray.100', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');

    return (
        <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center" bg={bgColor}>
            <VStack spacing={4} textAlign="center">
                <Heading color={textColor}>401 Unauthorized</Heading>
                <Text fontSize="xl" color={textColor}>
                    Sorry, you do not have permission to view this page.
                </Text>
                <Text color={textColor}>
                    You might need to log in or gain the necessary permissions to access this resource.
                </Text>
                <VStack spacing={2} mt={4}>
                    <Button bg="navigation.50"
                            _hover={{ bg: 'navigation.200', color: 'white' }}
                            color="primary.0" as={Link} to="/">
                        Go to Home
                    </Button>
                </VStack>
            </VStack>
        </Box>
    );
}

export default PermissionNotGranted;
