// GoogleLoginButton.tsx
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store';
import { googleLogin } from '~/store/authSlice';
import { useToast } from '@chakra-ui/react';

const GoogleLoginButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

    if (!clientId) {
        console.error("Google Client ID is not defined. Please make sure VITE_GOOGLE_CLIENT_ID is set in your environment variables.");
        return null; // Hide the button if there's no client ID
    }

    const handleLoginSuccess = (response: any) => {
        const idToken = response.credential;

        // Dispatch Google login action and handle the outcome
        dispatch(googleLogin(idToken))
            .unwrap()
            .then(() => {
                // toast({
                //     title: "Google Login Successful",
                //     description: "You have been logged in successfully.",
                //     status: "success",
                //     duration: 5000,
                //     isClosable: true,
                // });
                console.log("Successful login with Google");
            })
            .catch((error) => {
                toast({
                    title: "Google Login Failed",
                    description: error.message || "An error occurred while logging in with Google.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                console.error("Google login error:", error);
            });
    };

    const handleLoginFailure = () => {
        toast({
            title: "Google Login failed",
            description: "An error occurred while trying to log in with Google. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
        console.error("Google Login failed");
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
                size="large"
                theme="outline"
                text="signin_with" // Text on the button can be customized
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
