import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Text,
    Textarea,
    Button,
    VStack,
    Alert,
    Image,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    HStack,
    useBreakpointValue,
} from '@chakra-ui/react';
import { fetchModels, sendPrompt } from '~/store/imageSlice';
import { RootState, AppDispatch } from '~/store';
import { ArrowForwardIcon, SettingsIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { fetchBillingStatus, selectBillingStatus } from "~/store/billingSlice.ts";
import ModelSelect from "~/apps/backend/components/ModelSelect.tsx";
import ModelSettings from "~/apps/backend/components/ModelSettings.tsx";
import BillingAlerts from "~/apps/backend/components/BillingAlerts.tsx";

interface ChatMessage {
    type: 'system' | 'image';
    text: string;
    image_url?: string;
}

const Images = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { models } = useSelector((state: RootState) => state.images);
    const { loading, images } = useSelector((state: RootState) => state.images);
    const navigate = useNavigate();
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedModelType, setSelectedModelType] = useState('');

    const [settings, setSettings] = useState({
        quality: 'Medium',
        aspectRatio: '1:1',
        prompt: '',
        no_of_images: '1',
    });
    const [chats, setChats] = useState<ChatMessage[]>([]);
    const chatEndRef = useRef<HTMLDivElement>(null);
    const billingStatus = useSelector(selectBillingStatus);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false); // State for Drawer

    const handleSettingChange = (key: string, value: string) => {
        setSettings({ ...settings, [key]: value });
    };

    useEffect(() => {
        dispatch(fetchBillingStatus());
    }, [dispatch, images]);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        if(models.length && !selectedModel) {
            try {
                const defaultModel = models.find((m) => m.name === 'Default - FLUX1.1 PRO');
                setSelectedModel(defaultModel.public_id);
                setSelectedModelType(defaultModel.model_type);
            } catch(e) {
                console.error('Error selecting default model:', e);
            }
        }
    }, [models]);
    useEffect(() => {
        dispatch(
            fetchModels({ training_status: 'succeeded', state: 'active' })
        );
    }, [dispatch]);

    useEffect(() => {
        if (selectedModel) {
            setChats([]);
        }
    }, [selectedModel, dispatch]);

    useEffect(() => {
        if (images && images.length > 0) {
            const newImageChats = images.map((img) => ({
                type: 'image' as const,
                text: `Generated Image: ${img.prompt}`,
                image_url: img.image_url,
            }));
            setChats((prevChats) => [...prevChats, ...newImageChats]);
        }
    }, [images]);

    const handleSendPrompt = async () => {
        if (!selectedModel) {
            setChats((prev) => [...prev, { type: 'system', text: 'Please select a model.' }]);
            return;
        }

        const model = models.find((m) => m.public_id === selectedModel);
        if (model && !settings.prompt.includes(model.trigger_word) && model.model_type === 'custom') {
            setChats((prev) => [...prev, { type: 'system', text: `The prompt must include the trigger word: "${model.trigger_word}"` }]);
            return;
        }

        setChats((prev) => [...prev, { type: 'system', text: `You: ${settings.prompt}` }]);
        try {
            await dispatch(sendPrompt({ public_id: selectedModel, settings })).unwrap();
            handleSettingChange('prompt', '');
        } catch (error: any) {
            if (error.message.includes('code 403')) {
                navigate('/c-panel/billing?alt=lmti');
            } else {
                setChats((prev) => [...prev, { type: 'system', text: `Error: There was an error generating images, please try again or contact support.` }]);
                console.error('Failed to send prompt:', error.message || error);
            }
        }
    };

    const updateModelSelect = (model: { public_id: string, model_type: string }) => {
        setSelectedModel(model.public_id);
        setSelectedModelType(model.model_type);
    }

    const isSticky = useBreakpointValue({ base: true, md: false });

    return (
        <Box p={4} bg="gray.800" w="100%" h="100%" minH="100vh" display="flex" flexDirection="column">
            <Box
                maxW={{ base: '100%', md: '800px' }} // Full width on mobile, max width on larger screens
                w="100%" // Full width
                ml="auto"
                mr="auto"
                flex="1"
                display="flex"
                flexDirection="column"
                overflowY="auto"
                px={{ base: 2, md: 4 }} // Responsive padding
            >
                <Text fontSize="xl" fontWeight="bold" color="primary.0" textAlign="center" mb={2}>Generate Images</Text>

                <HStack
                    spacing={4}
                    align="center"
                    mb={2}
                    position={isSticky ? "sticky" : "static"}
                    top="0"
                    zIndex="2"
                    bg="gray.800"
                    p={2}
                >
                    <Box flex="1">
                        <ModelSelect selectedModel={selectedModel} setSelectedModel={updateModelSelect} />
                    </Box>
                    <IconButton
                        aria-label="Open Settings"
                        icon={<SettingsIcon />}
                        colorScheme="gray.800"
                        onClick={() => setIsSettingsOpen(true)}
                    />
                </HStack>

                {billingStatus?.user && (
                    <Box position="relative">
                        <BillingAlerts billingStatus={billingStatus} top={0} />
                    </Box>
                )}

                {/* Drawer for Settings */}
                <Drawer
                    isOpen={isSettingsOpen}
                    placement="right"
                    onClose={() => setIsSettingsOpen(false)}
                >
                    <DrawerOverlay />
                    <DrawerContent bg="gray.800">
                        <DrawerCloseButton color={'white'} />
                        <DrawerHeader color="white">Settings</DrawerHeader>
                        <DrawerBody>
                            <ModelSettings
                                modelSettings={settings}
                                handleSettingChange={handleSettingChange}
                                modelType={selectedModelType}
                            />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                <VStack spacing={4} align="stretch" flex="1" overflowY="auto" mb={0}>
                    {/* Chat messages */}
                    <VStack spacing={4} align="stretch" flex="1" overflowY="auto" bg="gray.800" pl={4} pr={4} borderRadius="md">
                        {chats.map((chat, index) => (
                            <Box key={index} w="100%">
                                {chat.type === 'system' && (
                                    <Alert status="info" borderRadius="md" bg="gray.700" color="primary.0">
                                        <Text>{chat.text}</Text>
                                    </Alert>
                                )}
                                {chat.type === 'image' && chat.image_url && (
                                    <Box borderRadius="md" p={4} shadow="md" borderWidth="1px" bg="gray.700">
                                        <Text mb={2} color="primary.0">{chat.text}</Text>
                                        <Image src={chat.image_url} alt="Generated Image" />
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </VStack>
                </VStack>

                {/* Prompt section */}
                <Box mt="auto" pl={4} pr={4} pb={0} bg="gray.800" w="100%">
                    {(selectedModel && models.find((m) => m.public_id === selectedModel)?.model_type === 'custom') && (
                        <Text color="primary.0" mb={2}>
                            Prompt must include the trigger word: <strong>{models.find((m) => m.public_id === selectedModel)?.trigger_word}</strong>
                        </Text>
                    )}
                    {(selectedModel && models.find((m) => m.public_id === selectedModel)?.model_type === 'flux') && (
                        <Text color="primary.0" mb={2}>
                            Flux models can be used to generate any image you can imagine. Just describe what you want to see!
                        </Text>
                    )}
                </Box>

                <Box mt="auto" pl={4} pr={4} bg="gray.800" w="100%" position="relative">
                    <div ref={chatEndRef}></div>
                    <Textarea
                        placeholder="Enter your prompt"
                        value={settings.prompt}
                        onChange={(e) => handleSettingChange('prompt', e.target.value)}
                        isDisabled={loading}
                        bg="gray.700"
                        color="primary.0"
                        _placeholder={{color: 'gray.400'}}
                        pr="60px"
                        resize="vertical"
                        w="100%"
                    />

                    <Button
                        colorScheme="blue"
                        onClick={handleSendPrompt}
                        isLoading={loading}
                        position="absolute"
                        right="10"
                        top="0"
                        bottom="0"
                        margin="auto"
                        borderRadius="full"
                        h="40px"
                        w="40px"
                        minW="40px"
                        p={0}
                        zIndex={3}
                    >
                        <ArrowForwardIcon boxSize={4}/>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Images;
