// src/store/popupSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PopupState {
    isOpen: boolean;
    title: string;
    subtitle: string;
    defaultMessageType: string;
}

const initialState: PopupState = {
    isOpen: false,
    title: '',
    subtitle: '',
    defaultMessageType: 'early_offer',
};

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        openPopup: (
            state,
            action: PayloadAction<{ title: string; subtitle: string; defaultMessageType?: string }>
        ) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.subtitle = action.payload.subtitle;
            state.defaultMessageType = action.payload.defaultMessageType || 'early_offer';
        },
        closePopup: (state) => {
            state.isOpen = false;
            state.title = '';
            state.subtitle = '';
            state.defaultMessageType = 'early_offer';
        },
    },
});

export const { openPopup, closePopup } = popupSlice.actions;
export default popupSlice.reducer;
