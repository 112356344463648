import React from 'react';
import {
    Box, Flex, IconButton, useDisclosure, Drawer, DrawerOverlay,
    DrawerContent, DrawerCloseButton, useBreakpointValue
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Sidebar from "../components/Sidebar.tsx";

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false }); // Dynamically detect mobile layout

    return (
        <Flex h="100vh" align="stretch">
            {/* Sidebar shown only on larger screens */}
            {!isMobile && (
                <Box w="250px" minH="100vh" bg="brand.800"> {/* Ensure Sidebar always has a background and full height */}
                    <Sidebar />
                </Box>
            )}

            {/* Hamburger icon for mobile */}
            {isMobile && (
                <IconButton
                    aria-label="Open menu"
                    icon={<HamburgerIcon />}
                    onClick={onOpen}
                    position="fixed"
                    top="2"
                    left="6"
                    bg="gray.500"
                    zIndex="1000"
                />
            )}

            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent maxW="250px" p="0" overflowY="auto">
                        <DrawerCloseButton top="4" right="4" color={"white"} zIndex="1" />
                        <Sidebar />
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>

            {/* Content area */}
            <Box flex="1"
                 p="5"
                 color="primary.0"
                 minH="100vh"
                 overflowY="auto"
                 px={0} py={0}
            >
                {children}
            </Box>
        </Flex>
    );
}

export default MainLayout;
