import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button as ChakraButton, Spinner,
} from '@chakra-ui/react';import {ChevronDownIcon} from "@chakra-ui/icons";
import {useSelector} from "react-redux";
import {RootState} from "~/store";
import {useRef} from "react";

interface ModelSelectProps {
    selectedModel: string | null;
    setSelectedModel: (model: { public_id: string; model_type: string }) => void;
}

const ModelSelect: React.FC<ModelSelectProps> = ({ selectedModel, setSelectedModel }) => {
    const { models, loading: modelsLoading } = useSelector((state: RootState) => state.images);

    const menuButtonRef = useRef<HTMLButtonElement | null>(null);

    return (
        <Box pl={4}>
            {modelsLoading ? (
                <Spinner size="md" color="primary.0" />
            ) : (
                <Menu>
                    <MenuButton
                        as={ChakraButton}
                        ref={menuButtonRef}
                        rightIcon={<ChevronDownIcon />}
                        bg="gray.700"
                        color="primary.0"
                        _hover={{ bg: 'gray.600' }}
                        w="100%"

                    >
                        {selectedModel ? models.find((m) => m.public_id === selectedModel)?.name : 'Select a model'}
                    </MenuButton>
                    <MenuList bg="gray.700" borderColor="gray.600"
                              zIndex={2000}
                              position={'relative'}
                              minW={menuButtonRef.current?.offsetWidth}>
                        {models.map((model) => (
                            <MenuItem
                                key={model.public_id}
                                onClick={() => setSelectedModel(model)}
                                bg="gray.700"
                                color="primary.0"
                                _hover={{ bg: 'gray.600', color: 'white' }}
                            >
                                {model.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            )}
        </Box>
    )
}

export default ModelSelect;