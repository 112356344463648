// src/apps/frontend/routes/index.tsx
import {Routes, Route, Navigate} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Collections from "~/apps/backend/pages/Collections.tsx";
import Billing from "~/apps/backend/pages/Billing.tsx";
import ModelDetail from "~/apps/backend/pages/ModelDetail.tsx";
import Images from "~/apps/backend/pages/Images.tsx";
import MyImages from "~/apps/backend/pages/MyImages.tsx";
import RemoveBackground from "~/apps/backend/pages/RemoveBackground.tsx";
import Support from "~/apps/backend/pages/Support.tsx";
import Tutorials from "~/apps/backend/pages/Tutorials.tsx";
import RemoveBackgroundDetail from "~/apps/backend/pages/RemoveBackgroundDetail.tsx";

export const BackendRoutes = () => {
    return (
        <MainLayout>
            <Routes>
                <Route path='/' element={<Collections />}/>
                <Route path='/models' element={<Collections />}/>
                <Route path="/model/:public_id" element={<ModelDetail />} />
                <Route path="/images" element={<Images />} />
                <Route path="/remove-background" element={<RemoveBackground />} />
                <Route path="/remove-background/:public_id" element={<RemoveBackgroundDetail />} />
                <Route path="/my-images" element={<MyImages />} />
                <Route path='/billing' element={<Billing />}/>
                <Route path='/support' element={<Support />}/>
                <Route path='/tutorials' element={<Tutorials />}/>
                <Route path="/*" element={<Navigate to="/errors/404" replace />} />
            </Routes>
        </MainLayout>
    );
}
