import {Route, Routes} from "react-router-dom";
import DefaultError from "../pages/DefaultError.tsx";
import NotFoundError from "../pages/NotFoundError.tsx";
import PermissionNotGranted from "../pages/PermissionNotGranted.tsx";

export const ErrorRoutes = () => {
    return (
        <Routes>
            <Route path="/404" element={<NotFoundError />} />
            <Route path="/401" element={<PermissionNotGranted />} />
            <Route path="*" element={<DefaultError />} />
        </Routes>
    );
}