import {Route, Routes} from "react-router-dom";
import {ErrorRoutes} from "../apps/errors";
import {AuthRoutes} from "../apps/auth";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/*" element={<AuthRoutes />} />
            <Route path="/errors/*" element={<ErrorRoutes />} />
        </Routes>
    );
}

export default PublicRoutes;