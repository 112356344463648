// ForgotPassword.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, clearPasswordResetState } from '~/store/authSlice';
import { RootState, AppDispatch } from '~/store';
import { Box, Input, Button, Text, FormControl, FormLabel, FormErrorMessage, VStack, useToast, Flex } from '@chakra-ui/react';

const ForgotPassword: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const { loading, error, passwordResetSuccess } = useSelector((state: RootState) => state.auth);

    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState<string | null>(null);

    useEffect(() => {
        if (passwordResetSuccess) {
            toast({
                title: 'Password reset complete.',
                description: 'If you have an account that that email address you will have received an email, please check.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setEmail('');
            dispatch(clearPasswordResetState());
        }
        if (error) {
            toast({
                title: 'Error',
                description: error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [passwordResetSuccess, error, dispatch, toast]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setFormError('Please enter a valid email address.');
            return;
        }

        setFormError(null);
        dispatch(forgotPassword(email));
    };

    return (
        <Flex minH="80vh" align="center" justify="center">
            <Box maxWidth="400px" w="full" p="4" borderWidth="1px" borderRadius="md" boxShadow="md">
                <Text fontSize="2xl" mb="4" textAlign="center">
                    Forgot Password
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl isInvalid={!!formError}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                            />
                            <FormErrorMessage>{formError}</FormErrorMessage>
                        </FormControl>

                        <Button type="submit" colorScheme="blue" isLoading={loading} width="full">
                            Send Reset Link
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default ForgotPassword;
