import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box, Text, VStack, Alert, Spinner, Image, Grid, GridItem, Button, useToast, Menu, MenuButton, MenuList, MenuItem, Button as ChakraButton
} from '@chakra-ui/react';
import {downloadCollection, downloadImage, fetchImages, fetchModels} from '~/store/imageSlice';
import { RootState, AppDispatch } from '~/store';
import { ChevronDownIcon } from "@chakra-ui/icons";


const MyImages = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();

    const { models, loading: modelsLoading } = useSelector((state: RootState) => state.images);
    const { loading, error_message, images } = useSelector((state: RootState) => state.images);

    const [selectedModel, setSelectedModel] = useState('');
    const menuButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (selectedModel) {
            dispatch(fetchImages(selectedModel));
        }
    }, [selectedModel, dispatch]);

    useEffect(() => {
        dispatch(fetchModels({
            training_status: 'succeeded',
            has_images: true,
        }));
    }, [dispatch]);

    const handleDownloadCollection = async () => {
        try {
            await dispatch(downloadCollection(selectedModel)).unwrap();
            toast({
                title: 'Download started',
                description: `Collection ${selectedModel}.zip is downloading.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Download failed',
                description: error as string,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDownloadImage = async (public_id: string) => {
        try {
            await dispatch(downloadImage(public_id)).unwrap();
            toast({
                title: 'Download started',
                description: `Image ${public_id} is downloading.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Download failed',
                description: error as string,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={4} bg="gray.800" minH="100vh" w="100%" display="flex" flexDirection="column">
            <Text fontSize="xl" fontWeight="bold" color="primary.0" textAlign="center" mb={4}>
                My Generated Images
            </Text>

            <VStack spacing={4} align="stretch" flex="1" overflowY="auto" mb={4}>
                {modelsLoading ? (
                    <Spinner size="md" color="primary.0" />
                ) : (
                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            ref={menuButtonRef}
                            rightIcon={<ChevronDownIcon />}
                            bg="gray.700"
                            color="primary.0"
                            _hover={{ bg: 'gray.600' }}
                            w="100%"
                        >
                            {selectedModel ? models.find((m) => m.public_id === selectedModel)?.name : 'Select a model'}
                        </MenuButton>
                        <MenuList
                            bg="gray.700"
                            borderColor="gray.600"
                            minW={menuButtonRef.current?.offsetWidth}
                        >
                            {models.map((model) => (
                                <MenuItem
                                    key={model.public_id}
                                    onClick={() => setSelectedModel(model.public_id)}
                                    bg="gray.700"
                                    color="primary.0"
                                    _hover={{ bg: 'gray.600', color: 'white' }}
                                    w="100%"
                                >
                                    {model.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )}
                {selectedModel && (
                    <Box>
                        <Button onClick={handleDownloadCollection} isLoading={loading} w="100%" colorScheme="blue">
                            Download Collection
                        </Button>
                    </Box>
                )}
            </VStack>

            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
                gap={4}
                flex="1"
                overflowY="auto"
                bg="gray.800"
                p={4}
                borderRadius="md"
            >
                {images.map((image, index) => (
                    <GridItem key={index} w="100%" position="relative" overflow="hidden" borderRadius="md">
                        {!image.image_url && (
                            <Box borderRadius="md" p={4} shadow="md"
                                 height="100%"
                                 borderWidth="1px" bg="gray.700">
                                <Spinner size="md" color="primary.0" />
                            </Box>
                        )}
                        <Box borderRadius="md" p={4} shadow="md" borderWidth="1px" bg="gray.700" position="relative">
                        {image.image_url && (
                            <Box position="relative">
                                <Image src={image.image_url} alt="Generated Image" borderRadius="md" />

                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width="100%"
                                    height="100%"
                                    bg="rgba(0, 0, 0, 0.6)"
                                    opacity="0"
                                    _hover={{ opacity: "1" }}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    transition="opacity 0.3s ease-in-out"
                                >
                                    <Button
                                        colorScheme="blue"
                                        onClick={() => handleDownloadImage(image.public_id)}
                                    >
                                        Download
                                    </Button>
                                </Box>
                            </Box>
                        )}
                            <Box mt={4}>
                                {image.prompt}
                            </Box>
                        </Box>
                    </GridItem>
                ))}
            </Grid>

            {error_message && (
                <Alert status="error" mt={4} borderRadius="md" bg="red.600" color="primary.0">
                    <Text>{error_message}</Text>
                </Alert>
            )}
        </Box>
    );
};

export default MyImages;
