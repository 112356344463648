// ResetPassword.tsx
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Input, Button, Text, FormControl, FormLabel, FormErrorMessage, VStack, useToast } from '@chakra-ui/react';
import API from '~/utils/api';

const ResetPassword: React.FC = () => {
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const navigate = useNavigate();
    const toast = useToast();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formError, setFormError] = useState<string | null>(null);

    // Password validation function
    const isPasswordValid = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Check if passwords match
        if (password !== confirmPassword) {
            setFormError('Passwords do not match.');
            return;
        }

        // Check if password meets complexity requirements
        if (!isPasswordValid(password)) {
            setFormError(
                'Password must be at least 6 characters and contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.'
            );
            return;
        }

        setFormError(null);  // Clear any previous form errors

        try {
            await API.post('/auth/complete-reset-password/', {
                uid,
                token,
                new_password: password,
            });

            // Show success toast and navigate to login
            toast({
                title: 'Password reset successful.',
                description: 'You can now log in with your new password.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Clear form fields and navigate to login
            setPassword('');
            setConfirmPassword('');
            navigate('/auth/login');
        } catch (error: any) {
            const error_message =
                typeof error.response?.data === 'string'
                    ? error.response.data
                    : typeof error.response?.data === 'object'
                        ? JSON.stringify(error.response.data)
                        : error.message || 'Failed to reset password';

            toast({
                title: 'Error resetting password.',
                description: error_message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box minH="80vh" display="flex" alignItems="center" justifyContent="center">
            <Box maxWidth="400px" w="full" p="4" borderWidth="1px" borderRadius="md" boxShadow="md">
                <Text fontSize="2xl" mb="4" textAlign="center">
                    Reset Password
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl isInvalid={!!formError}>
                            <FormLabel>New Password</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your new password"
                            />
                        </FormControl>

                        <FormControl isInvalid={!!formError}>
                            <FormLabel>Confirm New Password</FormLabel>
                            <Input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your new password"
                            />
                            <FormErrorMessage>{formError}</FormErrorMessage>
                        </FormControl>

                        <Button type="submit" colorScheme="blue" width="full">
                            Reset Password
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Box>
    );
};

export default ResetPassword;
