import { useEffect } from 'react';
import { Box, Heading, Text, Button, VStack, Icon, Spinner, Container } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '~/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPayment, selectLoading, selectError } from '~/store/billingSlice';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa'; // Import appropriate icons

// Helper function to parse query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const BillingSuccess = () => {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const query = useQuery();
    const public_id = query.get('user_id');
    const navigate = useNavigate();

    useEffect(() => {
        if (public_id) {
            dispatch(confirmPayment({ public_id }));
        }
    }, [public_id, dispatch]);

    return (
        <Container maxW="container.md" py={10}>
            {loading ? (
                <VStack spacing={4}>
                    <Spinner size="xl" />
                    <Text>Please wait while we confirm your payment details...</Text>
                </VStack>
            ) : error ? (
                <Box
                    p={8}
                    border="1px solid"
                    borderColor="red.300"
                    borderRadius="lg"
                    textAlign="center"
                    boxShadow="md"
                    bg="red.50"
                >
                    <Icon as={FaExclamationTriangle} boxSize={20} color="red.400" mb={4} />
                    <Heading as="h1" fontSize="2xl" mb={6} color="red.600">
                        Payment Error
                    </Heading>
                    <Text fontSize="lg" mb={6} color="red.700">
                        Unfortunately, we encountered an issue while processing your payment.
                    </Text>
                    <Text fontSize="md" mb={6} color="gray.600">
                        {error}
                    </Text>
                    <Button
                        colorScheme="red"
                        size="lg"
                        onClick={() => navigate('/')}
                        borderRadius="full"
                    >
                        Go Back to Home
                    </Button>
                </Box>
            ) : (
                <Box
                    p={8}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="lg"
                    textAlign="center"
                    boxShadow="md"
                >
                    <Icon as={FaCheckCircle} boxSize={20} color="green.400" mb={4} />
                    <Heading as="h1" fontSize="2xl" mb={6}>
                        Payment Successful!
                    </Heading>
                    <Text fontSize="lg" mb={6}>
                        Your payment was successful. Thank you for your purchase and for choosing our service.
                    </Text>
                    <VStack spacing={3} mb={6}>
                        <Text fontSize="md" color="gray.600">
                            You now have access to all the premium features of the selected plan.
                        </Text>
                        <Text fontSize="md" color="gray.600">
                            Please check your email for more details or visit your account dashboard.
                        </Text>
                    </VStack>
                    <Button
                        colorScheme="blue"
                        size="lg"
                        onClick={() => navigate('/c-panel/models')}
                        borderRadius="full"
                    >
                        Go to Models
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default BillingSuccess;
