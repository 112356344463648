import {Box} from "@chakra-ui/react";
import PricingSection from "~/components/billing/PricingSection.tsx";

const billing = () => {
    return (
        <Box minH="100vh" bg="gray.800" color="black">
            <PricingSection></PricingSection>
        </Box>
    );
}

export default billing;