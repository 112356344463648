// src/routes/index.tsx
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PublicRoutes from './public';
import ProtectedRoutes from "./protected.tsx";
import ScrollToTop from "../apps/frontend/components/ScrollToTop.tsx";

const AppRoutes = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/*" element={<PublicRoutes />} />
                <Route path="/c-panel/*" element={<ProtectedRoutes />} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;
