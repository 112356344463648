import { Box, Heading, Text, Button, VStack, Icon, Container } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa'; // Import a cancellation icon

const BillingCancellation = () => {
    const navigate = useNavigate();

    return (
        <Container maxW="container.md" py={10}>
            <Box
                p={8}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="lg"
                textAlign="center"
                boxShadow="md"
                bg="gray.50"
            >
                <Icon as={FaTimesCircle} boxSize={20} color="red.400" mb={4} />
                <Heading as="h1" fontSize="2xl" mb={6} color="red.600">
                    Billing Cancelation
                </Heading>
                <Text fontSize="lg" mb={6} color="gray.800">
                    Your billing process was canceled. If this was unintentional, you can try again or reach out to our support team for assistance.
                </Text>
                <VStack spacing={4} mb={6}>
                    <Text fontSize="md" color="gray.600">
                        We are here to help if you have any questions or need support.
                    </Text>
                </VStack>
                <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={() => navigate('/c-panel/billing')}
                    borderRadius="full"
                >
                    Back to Pricing
                </Button>
            </Box>
        </Container>
    );
};

export default BillingCancellation;
