// SupportPage.tsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '~/store';
import {
    Box,
    Heading,
    Text,
    VStack,
    Textarea,
    Button,
    Icon,
    FormControl,
    FormLabel,
    useToast
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import {submitMessage} from "~/store/submissionSlice.ts";

const SupportPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [message, setMessage] = useState('');
    const { status, error } = useSelector((state: RootState) => state.submission);
    const toast = useToast();

    const handleSubmit = () => {
        if (!message) {
            toast({
                title: 'Message required.',
                description: 'Please enter a message before submitting.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        dispatch(submitMessage({ name: 'Support', email: 'system@message', message, message_type: 'support' }));
    };

    if (status === 'succeeded') {
        return (
            <Box bg="gray.800" minH="100vh" display="flex" alignItems="center" justifyContent="center">
                <Box
                    p={8}
                    bg="gray.700"
                    border="1px solid"
                    borderColor="gray.600"
                    borderRadius="lg"
                    textAlign="center"
                    boxShadow="lg"
                    maxW="600px"
                >
                    <Icon as={FaCheckCircle} boxSize={20} color="green.400" mb={4} />
                    <Heading as="h1" fontSize="2xl" mb={6} color="white">
                        Thank You for Your Message!
                    </Heading>
                    <Text fontSize="lg" mb={6} color="gray.200">
                        Your support message was successfully sent. We will get back to you as soon as possible.
                    </Text>
                    <VStack spacing={3} mb={6}>
                        <Text fontSize="md" color="gray.400">
                            Please check your email for further updates.
                        </Text>
                        <Text fontSize="md" color="gray.400">
                            We appreciate your patience and are here to help.
                        </Text>
                    </VStack>
                </Box>
            </Box>
        );
    }

    return (
        <Box bg="gray.800" minH="100vh" display="flex" alignItems="center" justifyContent="center">
            <Box
                p={8}
                bg="gray.700"
                border="1px solid"
                borderColor="gray.600"
                borderRadius="lg"
                boxShadow="lg"
                maxW="600px"
                w="full"
            >
                <Heading as="h1" fontSize="2xl" mb={6} textAlign="center" color="white">
                    Contact Support
                </Heading>
                <FormControl mb={4} isRequired>
                    <FormLabel color="gray.300">Message</FormLabel>
                    <Textarea
                        placeholder="Describe your issue or question..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        size="lg"
                        resize="vertical"
                        bg="gray.600"
                        color="white"
                        _placeholder={{ color: 'gray.400' }}
                    />
                </FormControl>
                <Button
                    colorScheme="blue"
                    size="lg"
                    width="full"
                    mt={4}
                    onClick={handleSubmit}
                    isLoading={status === 'loading'}
                    loadingText="Submitting"
                    disabled={status === 'loading'}
                    borderRadius="full"
                >
                    Submit Message
                </Button>
                {error && (
                    <Text color="red.400" mt={4} textAlign="center">
                        {error}
                    </Text>
                )}
            </Box>
        </Box>
    );
};

export default SupportPage;
