import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from "~/utils/api.ts";

// Define the interface for the message submission payload
interface SubmitMessagePayload {
    name: string;
    email: string;
    message?: string;
    message_type: string;
}

// Define the submission state
interface SubmissionState {
    email: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: SubmissionState = {
    email: null,
    status: 'idle',
    error: null,
};

// Thunk for handling async form submission with the updated payload structure
export const submitMessage = createAsyncThunk(
    'submission/submitMessage',
    async (payload: SubmitMessagePayload) => {
        const response = await API.post(`/support/create-ticket/`, payload);
        return response.data;
    }
);

const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitMessage.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(submitMessage.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = 'succeeded';
                state.email = action.payload; // Assuming the API returns the email or other response data
            })
            .addCase(submitMessage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Submission failed';
            });
    },
});

export default submissionSlice.reducer;
