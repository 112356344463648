import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '~/store/authSlice'; // Ensure this points to your correct slice
import { AppDispatch } from '~/store';

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        // Dispatch the logout action to clear tokens from Redux and localStorage
        dispatch(logout());

        // Redirect to the home page after logging out
        navigate('/login');
    }, [dispatch, navigate]);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );
}

export default Logout;
