import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../utils/api';

// Define the initial state
interface ImageState {
    collection: any | null;
    images: any[];
    models: any[];
    status: string;
    error_message: string | null;
    loading: boolean;
}

const initialState: ImageState = {
    collection: null,
    images: [],
    models: [],
    status: '',
    error_message: null,
    loading: false,
};

// Async thunk to fetch image generation data (GET)
export const fetchImages = createAsyncThunk(
    'images/fetchImages',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/generate-images/`);
        return response.data;
    }
);

// Async thunk to send prompt to generate images (POST)
export const sendPrompt = createAsyncThunk(
    'images/sendPrompt',
    async ({ public_id, settings }: { public_id: string, settings: object}) => {
        const response = await API.post(`/cartario/collection/${public_id}/generate-images/`, settings);
        return response.data;
    }
);


interface FetchModelsPayload {
    training_status?: string | null;
    state?: string | null;
    has_images?: boolean | null;
}

export const fetchModels = createAsyncThunk(
    'images/fetchModels',
    async (payload: FetchModelsPayload = { training_status: null, state: null, has_images: null }) => {
        // Build params object with non-null parameters
        const params: Record<string, string | boolean> = {};
        if (payload.training_status != null) {  // checks for both null and undefined
            params.training_status = payload.training_status;
        }
        if (payload.state != null) {
            params.state = payload.state;
        }

        if (payload.has_images != null) {
            params.has_images = payload.has_images;
        }

        const response = await API.get('/cartario/collections/', { params });
        const models = response.data.results;

        // Filter models to keep the latest unique public_id
        const uniqueModels = models.reduce((acc: any[], currentModel: any) => {
            const existingModel = acc.find((m) => m.public_id === currentModel.public_id);

            // If no existing model or the current model has a more recent updated_at, use the current one
            if (!existingModel || new Date(currentModel.updated_at) > new Date(existingModel.updated_at)) {
                acc = acc.filter((m) => m.public_id !== currentModel.public_id); // Remove the older one
                acc.push(currentModel); // Add the newer one
            }
            return acc;
        }, []);

        return uniqueModels;
    }
);

export const downloadImage = createAsyncThunk<string, string, { rejectValue: string }>(
    'images/downloadImage',
    async (public_id, { rejectWithValue }) => {
        try {
            // Use API.get and set the response type to 'blob'
            const response = await API.get(`/cartario/image-download/${public_id}/`, {
                responseType: 'blob',  // Ensure the response is a Blob for file download
            });
            //get extension from response.data
            const contentType = response.headers['content-type'];
            const extension = contentType.split('/')[1];

            // Convert the response data to a URL and create a link for download
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${public_id}.${extension}`; // Set the file name to public_id.png
            document.body.appendChild(link);
            link.click();

            // Clean up the link and URL
            link.remove();
            window.URL.revokeObjectURL(url);

            return 'Download succeeded';
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Download failed');
        }
    });

export const downloadCollection = createAsyncThunk<string, string, { rejectValue: string }>(
    'images/downloadCollection',
    async (public_id, { rejectWithValue }) => {
        try {
            // Use API.get and set the response type to 'blob'
            const response = await API.get(`/cartario/collection-queue-download/${public_id}`, {
                responseType: 'blob',  // Ensure the response is a Blob for file download
            });

            // Convert the response data to a URL and create a link for download
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${public_id}.zip`; // Set the file name to public_id.zip
            document.body.appendChild(link);
            link.click();

            // Clean up the link and URL
            link.remove();
            window.URL.revokeObjectURL(url);

            return 'Download succeeded';
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Download failed');
        }
    }
);


// Create a slice for images
const imageSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchImages.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.loading = false;
                state.collection = action.payload.collection;
                state.collection.trigger_word = action.payload.trigger_word || 'N/A';
                state.images = action.payload.images;
                state.status = action.payload.status;
                state.error_message = action.payload.error_message;
            })
            .addCase(fetchImages.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to fetch images';
            })
            .addCase(sendPrompt.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPrompt.fulfilled, (state, action) => {
                state.loading = false;
                if(action.payload.length) {
                    state.images = action.payload;
                }
            })
            .addCase(sendPrompt.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to send prompt';
            })
            .addCase(fetchModels.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchModels.fulfilled, (state, action) => {
                state.loading = false;
                state.models = action.payload; // Store models in state
            })
            .addCase(fetchModels.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to fetch models';
            })
            .addCase(downloadCollection.pending, (state) => {
                state.loading = true;
                state.error_message = null;
            })
            .addCase(downloadCollection.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadCollection.rejected, (state, action) => {
                // Since action.payload can be string or undefined, we default to a fallback message
                state.loading = false;
                state.error_message = action.payload || 'Failed to download collection';
            })
            .addCase(downloadImage.pending, (state) => {
                state.loading = true;
                state.error_message = null;
            })
            .addCase(downloadImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadImage.rejected, (state, action) => {
                // Since action.payload can be string or undefined, we default to a fallback message
                state.loading = false;
                state.error_message = action.payload || 'Failed to download collection';
            });
    },
});

export default imageSlice.reducer;
