import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Textarea,
    Grid,
    GridItem,
    Image,
    VStack,
    Box,
    IconButton
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '~/store';
import { fetchGalleries } from '~/store/gallerySlice.ts';
import { FaFolder, FaArrowLeft } from 'react-icons/fa';

interface BackgroundProps {
    isVisible: boolean;
    onClose: () => void;
}

const BackgroundModal: React.FC<BackgroundProps> = ({ isVisible, onClose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [backgroundPrompt, setBackgroundPrompt] = useState("");
    const [selectedGallery, setSelectedGallery] = useState<string | null>(null);
    const [showTextField, setShowTextField] = useState(true);
    const [showGalleries, setShowGalleries] = useState(true);

    const galleries = useSelector((state: RootState) => state.gallery.galleries);

    useEffect(() => {
        dispatch(fetchGalleries());
    }, [dispatch]);

    const handleGenerateBackground = () => {
        console.log("Generating background with prompt:", backgroundPrompt);
        onClose();
        setBackgroundPrompt("");
    };

    const handleSelectGallery = (galleryId: string) => {
        setSelectedGallery(galleryId);
        setShowGalleries(false);
    };

    const toggleView = () => {
        setShowTextField(!showTextField);
        setSelectedGallery(null);
        setShowGalleries(true);
    };

    const resetGalleries = () => {
        setSelectedGallery(null);
        setShowGalleries(true);
    }
    return (
        <Modal isOpen={isVisible} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bgColor="gray.700" color="white" maxW="800px">
                <ModalHeader>
                    {selectedGallery ? (
                        <IconButton
                            icon={<FaArrowLeft />}
                            aria-label="Back"
                            onClick={() => resetGalleries()}
                            mr={2}
                        />
                    ) : null}
                    {selectedGallery ? "Select an Image" : "Create New Background Using Flux"}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {showTextField ? (
                        <Textarea
                            placeholder="Describe the background you'd like to generate"
                            value={backgroundPrompt}
                            onChange={(e) => setBackgroundPrompt(e.target.value)}
                            bg="gray.800"
                            color="white"
                            resize="vertical"
                        />
                    ) : showGalleries ? (
                        <Grid mt={4} templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                            {galleries.map((gallery) => (
                                <GridItem key={gallery.public_id}>
                                    <Button
                                        onClick={() => handleSelectGallery(gallery.public_id)}
                                        variant="ghost"
                                        width="100%"
                                        minH={120}
                                        color="gray.500"
                                        p={0}
                                        _hover={{ bg: "gray.600", color: "white" }}
                                    >
                                        <Box p={2} width="100%">
                                            <VStack>
                                                <FaFolder size="50px" />
                                                <span>{gallery.name}</span>
                                            </VStack>
                                        </Box>
                                    </Button>
                                </GridItem>
                            ))}
                        </Grid>
                    ) : (
                        <Grid mt={4} templateColumns="repeat(auto-fill, minmax(100px, 1fr))" gap={4}>
                            {galleries
                                .find((gallery) => gallery.public_id === selectedGallery)
                                ?.images.map((image) => (
                                    <GridItem key={image.public_id}>
                                        <Image
                                            src={image.image_url}
                                            alt="Gallery Image"
                                            boxSize="100px"
                                            objectFit="cover"
                                        />
                                    </GridItem>
                                ))}
                        </Grid>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!selectedGallery && (
                        <Button colorScheme="blue" mr={3} onClick={toggleView}>
                            {showTextField ? "Use Existing Backgrounds" : "Create New Background Using Flux"}
                        </Button>
                    )}

                    {showTextField && (
                        <Button colorScheme="blue" onClick={handleGenerateBackground}>
                            Generate
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default BackgroundModal;