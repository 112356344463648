// PricingSection.tsx

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Container, Heading, Stack, Text, useToast, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch } from '~/store';
import PricingCard from './PricingCard';
import { fetchPricingPlans, fetchBillingStatus, selectPlans, selectLoading, selectError, selectBillingStatus } from '~/store/billingSlice';

interface AlertState {
    title: string | null;
    message: string | null;
}

const PricingSection: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const [searchParams, setSearchParams] = useSearchParams();
    const alt = searchParams.get("alt");
    const [alert, setAlert] = useState<AlertState>({ title: null, message: null });

    const plans = useSelector(selectPlans);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const billingStatus = useSelector(selectBillingStatus);

    useEffect(() => {
        dispatch(fetchPricingPlans());
        dispatch(fetchBillingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast({
                title: "An error occurred",
                description: error,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [error, toast]);

    // Helper function to set alert based on parameters and billing status
    const updateAlert = () => {
        if (alt === "lmti") {
            if (!billingStatus?.billing_plan_id) {
                setAlert({
                    title: "Image Generation Limit Reached",
                    message: "Your trial includes a limit of 5 image generations. To continue creating more images and unlock advanced features, please consider subscribing.",
                });
            } else {
                setAlert({
                    title: "Image Generation Limit Reached",
                    message: "You’ve reached the image generation limit for this cycle. Consider upgrading for additional access, or wait until the next cycle to generate more images.",
                });
            }
        } else if (alt === "lmtc") {
            if (!billingStatus?.billing_plan_id) {
                setAlert({
                    title: "Subscription Required for Model Training",
                    message: "Model creation and training are only available to subscribers. Start your subscription today to unlock these features and enhance your AI experience.",
                });
            }  else {
                setAlert({
                    title: "Monthly Model Creation Limit Reached",
                    message: "You’ve reached the model creation limit for this month. Consider upgrading for higher limits or wait until the next cycle to create more models.",
                });
            }
        } else if (alt === "rmbck") {
            setAlert({
                title: "Subscription Required for Background Removal",
                message: "To remove / replace backgrounds, you need to subscribe to a plan. Start your subscription today to unlock this feature and enhance your AI experience.",
            });
        } else {
            setAlert({ title: null, message: null }); // Clear alert if no matching alt
        }
    };

    // Update alert whenever `alt` or `billingStatus` changes
    useEffect(() => {
        updateAlert();
    }, [alt, billingStatus]);

    if (loading) return <p>Loading...</p>;

    return (
        <Box id="pricing" py="80px" bg="#f5f5f5">
            <Container maxW="container.xxl">
                {alert.title && (
                    <Alert status="info" borderRadius="md" mb={6}>
                        <AlertIcon />
                        <Box flex="1">
                            <AlertTitle>{alert.title}</AlertTitle>
                            <AlertDescription>{alert.message}</AlertDescription>
                        </Box>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setSearchParams({})} />
                    </Alert>
                )}
                <Box textAlign="center" mb="20">
                    <Text fontSize="lg" fontWeight="bold" color="body.2" mb={4}>
                        Pricing
                    </Text>
                    <Heading fontSize="5xl" mb="6">
                        Our Pricing Plans
                    </Heading>
                    <Text maxW="600px" fontSize="large" mx="auto">
                        Choose a plan that suits your needs and start transforming your marketing today.
                    </Text>
                </Box>
                <Stack direction={['column', 'row']} spacing="0" alignItems="center" justifyContent="center" mt="8">
                    {plans.map((plan, index) => (
                        <PricingCard
                            key={index}
                            title={plan.title}
                            price={`$${parseInt(plan.price)}/mo`}
                            features={Array.isArray(plan.features) ? plan.features : []}
                            popular={plan.is_popular}
                            price_id={plan.stripe_price_id}
                            billingStatus={billingStatus}  // Pass billingStatus to PricingCard
                            order={plan.order}
                        />
                    ))}
                </Stack>
            </Container>
        </Box>
    );
};

export default PricingSection;
