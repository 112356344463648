import { Box, Heading, Text, Button, VStack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const NotFoundError = () => {
    const bgColor = useColorModeValue('gray.100', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');
    return (
        <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center" bg={bgColor}>
            <VStack spacing={4} textAlign="center">
                <Heading color={textColor}>404 Not Found</Heading>
                <Text fontSize="xl" color={textColor}>
                    Oops! The page you are looking for has disappeared or never existed.
                </Text>
                <Text color={textColor}>
                    Maybe you mistyped the address or the page has been moved to a different URL.
                </Text>
                <Button bg="navigation.50"
                        _hover={{ bg: 'navigation.200', color: 'white' }}
                        color="primary.0" as={Link} to="/">
                    Go to Home
                </Button>
            </VStack>
        </Box>
    );
}

export default NotFoundError;