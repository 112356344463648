// src/apps/frontend/routes/index.tsx
import {Routes, Route, Navigate} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Login from "../pages/Login.tsx";
import Logout from "../pages/Logout.tsx";
import Register from "../pages/Register.tsx";
import Forgot from "../pages/Forgot.tsx";
import ResetPassword from "../pages/ResetPassword.tsx";
import BillingSuccess from "~/apps/backend/pages/BillingSuccess.tsx";
import BillingCancellation from "~/apps/backend/pages/BillingCancellation.tsx";



export const AuthRoutes = () => {
    return (
        <MainLayout>
            <Routes>
                <Route path='/' element={<Login />}/>
                <Route path='/login' element={<Login />}/>
                <Route path='/logout' element={<Logout />}/>
                <Route path='/register' element={<Register />}/>
                <Route path='/forgot-password' element={<Forgot />}/>
                <Route path='/reset-password/:uid/:token' element={<ResetPassword />} />
                <Route path='/billing/success' element={<BillingSuccess />}/>
                <Route path='/billing/cancel' element={<BillingCancellation />}/>
                <Route path="*" element={<Navigate to="/errors/404" replace />} />
            </Routes>
        </MainLayout>
    );
}
