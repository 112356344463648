// authSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../utils/api';

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    loading: boolean;
    error: string | null;
    passwordResetSuccess: boolean;
}

const tokenFromStorage = localStorage.getItem('token');
const refreshTokenFromStorage = localStorage.getItem('refreshToken');

const initialState: AuthState = {
    token: tokenFromStorage || null,
    refreshToken: refreshTokenFromStorage || null,
    loading: false,
    error: null,
    passwordResetSuccess: false,
};

// Login thunk
export const login = createAsyncThunk('auth/login', async (credentials: { username: string; password: string; captcha_token: string; }) => {
    const response = await API.post('/auth/login/', credentials);
    return response.data;
});

// authSlice.ts
export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await API.post('/auth/forgot-password/', { email });
            return response.data;
        } catch (error: any) {
            const message =
                typeof error.response?.data === 'string'
                    ? error.response.data
                    : typeof error.response?.data === 'object'
                        ? JSON.stringify(error.response.data)
                        : error.message || 'Failed to send reset email';
            return rejectWithValue(message);
        }
    }
);

export const googleLogin = createAsyncThunk(
    'auth/googleLogin',
    async (idToken: string) => {
        const response = await API.post('/auth/google-login/', { token: idToken });
        return response.data;
    }
);

// Register thunk
export const register = createAsyncThunk(
    'auth/register',
    async (userData: { email: string; password: string; captcha_token:string }, { rejectWithValue }) => {
        try {
            const response = await API.post('/auth/register/', userData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Registration failed');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            state.token = null;
            state.refreshToken = null;
        },
        clearPasswordResetState: (state) => {
            state.passwordResetSuccess = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.access;
                state.refreshToken = action.payload.refresh;
                localStorage.setItem('token', action.payload.access);
                localStorage.setItem('refreshToken', action.payload.refresh);
                state.loading = false;
                state.error = null;
            })
            .addCase(register.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.passwordResetSuccess = true;
                state.loading = false;
                state.error = null;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.passwordResetSuccess = false;
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.token = action.payload.access;
                state.refreshToken = action.payload.refresh;
                localStorage.setItem('token', action.payload.access);
                localStorage.setItem('refreshToken', action.payload.refresh);
            });
    },
});

export const { logout, clearPasswordResetState } = authSlice.actions;
export default authSlice.reducer;
