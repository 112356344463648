import axios from 'axios';
import {ErrorObject} from "~/utils/types.ts";

export const getErrorMessage = (err: unknown, defaultMessage = 'Please try again.'): string => {
    if (!err || typeof err !== 'object') {
        return defaultMessage;
    }

    // Type guard to ensure err is an ErrorObject
    const errorObj = err as ErrorObject;
    const [firstKey] = Object.keys(errorObj);

    return firstKey && Array.isArray(errorObj[firstKey]) && errorObj[firstKey].length > 0
        ? firstKey + ' ' + errorObj[firstKey][0]
        : defaultMessage;
};

const BASE_API_URL = import.meta.env.VITE_APP_BASE_API_URL;

// List of endpoints that don’t need auth or refresh
const public_allowed = ['/login', '/register', '/refresh'];

const API = axios.create({
    baseURL: BASE_API_URL,
    timeout: 120000,
});

API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        // Check if the request URL includes any path in `public_allowed`
        if (token && !public_allowed.some(url => config.url?.includes(url))) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Utility to clear tokens and redirect to login with expired message
const deleteTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login?at=expired';  // Redirect on session expiration
}

API.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If unauthorized and not a retry, attempt refresh
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');

            if (!refreshToken || public_allowed.some(url => originalRequest.url.includes(url))) {
                // If no refresh token or if it's a public route, reject
                deleteTokens();
                return Promise.reject(error);
            }

            try {
                const res = await API.post('/auth/refresh/', { refresh: refreshToken });
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.access);
                    API.defaults.headers.common['Authorization'] = `Bearer ${res.data.access}`;
                    return API(originalRequest);
                }
            } catch (refreshError) {
                console.log('REFRESH ERROR!', refreshError);
                deleteTokens();
                return Promise.reject(refreshError);
            }
        }

        if (error.response.status === 401) {
            console.log(`Error ${error.response.status}: Unauthorized`);
            deleteTokens();
        }

        return Promise.reject(error);
    }
);

export default API;
