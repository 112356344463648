import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '~/store/authSlice'; // Adjust path based on your project structure
import { RootState, AppDispatch } from '~/store';
import {
    Box,
    Input,
    Button,
    Text,
    FormControl,
    FormLabel,
    FormErrorMessage,
    VStack,
    useToast,
    Flex
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from "~/apps/auth/components/GoogleLoginButton";
import ReCAPTCHA from "react-google-recaptcha";
import {getErrorMessage} from "~/utils/api.ts";

interface FormErrors {
    email?: string;
    password?: string;
    confirmPassword?: string;
    captchaToken?: string;
}

const Register: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const navigate = useNavigate();
    const { loading, token } = useSelector((state: RootState) => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [captchaToken, setCaptchaToken] = useState('');

    // Get reCAPTCHA site key from Vite environment variable
    const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
    const ALLOW_EMAIL_REGISTRATION = import.meta.env.VITE_ALLOW_EMAIL_REGISTRATION === 'true';

    useEffect(() => {
        if (token) {
            navigate('/c-panel/images');
        }
    }, [token, navigate]);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password: string): boolean => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/;
        return passwordRegex.test(password);
    };

    const handleCaptchaChange = (token: string | null): void => {
        setCaptchaToken(token || '');
        if (formErrors.captchaToken) {
            setFormErrors(prev => ({
                ...prev,
                captchaToken: undefined
            }));
        }
    };

    const validateForm = (): boolean => {
        const errors: FormErrors = {};

        if (!email) {
            errors.email = 'Email is required';
        } else if (!validateEmail(email)) {
            errors.email = 'Invalid email address';
        }

        if (!password) {
            errors.password = 'Password is required';
        } else if (!validatePassword(password)) {
            errors.password = 'Password must be at least 6 characters and include uppercase, lowercase, number, and special character';
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        if (!captchaToken) {
            errors.captchaToken = 'Please complete the captcha';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            await dispatch(register({
                email,
                password,
                captcha_token: captchaToken,
            })).unwrap();

            toast({
                title: 'Registration successful.',
                description: 'You can now log in with your credentials.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/login');
        } catch (err) {
            toast({
                title: 'Registration failed.',
                description: getErrorMessage(err),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex minH="80vh" align="center" justify="center">
            <Box maxWidth="400px" w="full" p="4" borderWidth="1px" borderRadius="md" boxShadow="md">
                <Text fontSize="2xl" mb="4" textAlign="center">
                    Register
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <GoogleLoginButton />

                        {ALLOW_EMAIL_REGISTRATION && (
                            <>
                                <FormControl isInvalid={!!formErrors.email} isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                    />
                                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.password} isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                    />
                                    <FormErrorMessage>{formErrors.password}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.confirmPassword} isRequired>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <Input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your password"
                                    />
                                    <FormErrorMessage>{formErrors.confirmPassword}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.captchaToken} isRequired>
                                    <Box w="100%">
                                        <ReCAPTCHA
                                            sitekey={RECAPTCHA_SITE_KEY}
                                            onChange={handleCaptchaChange}
                                        />
                                        {formErrors.captchaToken && (
                                            <FormErrorMessage>{formErrors.captchaToken}</FormErrorMessage>
                                        )}
                                    </Box>
                                </FormControl>

                                <Button
                                    type="submit"
                                    colorScheme="blue"
                                    isDisabled={loading || !captchaToken}
                                    isLoading={loading}
                                    width="full"
                                >
                                    Register
                                </Button>
                            </>
                        )}
                    </VStack>
                </form>
            </Box>
        </Flex>
    );
};

export default Register;