import { Box, Heading, AspectRatio, Container, Text } from '@chakra-ui/react';

const Tutorials: React.FC = () => {
    return (
        <Box minH="100vh" bg="gray.800" display="flex" alignItems="center" justifyContent="center" color="white">
            <Container maxW="container.md" centerContent>
                <Heading as="h1" mb={4} size="lg" textAlign="center" color="teal.200">
                    Welcome to the Tutorial
                </Heading>
                <Text mb={6} fontSize="lg" textAlign="center">
                    Watch the video below to get started!
                </Text>

                <AspectRatio ratio={16 / 9} w="full" maxW="600px" borderRadius="md" overflow="hidden" boxShadow="lg">
                    <iframe
                        title="YouTube video tutorial"
                        src="https://www.youtube.com/embed/6TyGAa_rPJQ"
                        allowFullScreen
                    />
                </AspectRatio>
            </Container>
        </Box>
    );
};

export default Tutorials;
